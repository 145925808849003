import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { CustomersHero, CustomersTestimonials } from "../sections/customers"

import { CtaGetDemo } from "components/cta"

const CustomersPage = ({ data: { customers } }) => {
  const {
    customers_hero,
    cta_get_demo,
    customers_testimonials,
  } = useContentfulSection(customers.sections)

  const meta = useMeta(customers)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <CustomersHero {...customers_hero} />
        <CustomersTestimonials {...customers_testimonials} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    customers: contentfulPage(pageId: { eq: "customers" }) {
      ...Page
    }
  }
`

export default CustomersPage
